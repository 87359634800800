import React from 'react';

import RasLogo from '../images/clientLogos/RASClublogo.png';
import RamGolf from '../images/clientLogos/RamGolflogo.png';
import JprClub from '../images/clientLogos/JprClub.png';
import JaiClub from '../images/clientLogos/jaiclub.png';
import Akshat from '../images/clientLogos/AkshatKanotaLogo.png';
import AshokClub from '../images/clientLogos/ashok club logo.png';
import Baradari from '../images/clientLogos/city palace.png';
import Kota from '../images/clientLogos/kotal club.png';
import Mewar from '../images/clientLogos/mewar club.png';
import Diamond from '../images/clientLogos/urbana.png';

export default function ETClubClients() {

    const images = [
        { src: RasLogo, name: "RAS Officers Institute" },
        { src: RamGolf, name: "Rambagh Golf Club" },
        { src: JprClub, name: "Jaipur Club Limited" },
        { src: JaiClub, name: "Jai Club" },
        { src: Akshat, name: "Akshat Kanota Estate" },
        { src: AshokClub, name: "Ashok Club" },
        { src: Baradari, name: "Baradari" },
        { src: Kota, name: "Kota Club" },
        { src: Mewar, name: "Mewar Club" },
        { src: Diamond, name: "Urbana Jewels Club" },
    ];
    return (
        <div>
            <div className='container-fluid g-0'>
                <div className='row g-2'>
                    {images.map((item, index) => (
                        <div key={index} className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
                            <div className='card d-flex align-items-center justify-content-center rounded-0 client-card p-3' style={{ width: "100%", height:250 }}>
                                <img src={item.src} alt={item.name} className='img-fluid' />
                                <div className='text-center mt-2' style={{ color: "#2e4053", fontSize: "16px", fontWeight: 500, }}>{item.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
