import React, { useState, useRef } from 'react';

export default function ClientNameSlide() {

    const texts = [
        'Brijwasi Gems', 'Darshana Fashion LLP', 'Heena Enterprises', 'Hunny Sarees', 'Kanisha Fashion', 'Katta Impex', 'G Malpani Agency LLP', 'M K Creation', 'Maharani Fabric', 'Rubi Creation', 'Ramgopal, Ramballabh', 'Roop Sons', 'Roop Rang Fab Tex', 'Riya Sarees', 'Shubhangam', 'Sheela Designer Sarees', 'Urban Basis', 'Vedganga Craft', 'Outfit Outlet',
    ];

    return (
        <div className="container mt-5">
            <div
                className="scroll-marquee"
                style={{ '--t': '60s' }}
            >
                {Array(2).fill('').map((_, index) => (
                    <div key={index} className="marquee-inner">
                        {texts.map((text, idx) => (
                            <span key={idx}>"{text}"</span>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
